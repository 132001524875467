import logo from "./logo.svg";
import "./App.css";
import Home from "./Home.";
import Services from "./Services";
import Aboutus from "./Aboutus.js";
import Testimonials from "./Testimonials.js";
import Contact from "./Contact.js";
import Header from "./Header.js";

function App() {
  return (
    <div className="App">
      <Header />
      <Home />
      <Aboutus />
      <Services />
      <Testimonials />
      <Contact />
    </div>
  );
}

export default App;
