import React from "react";
import { Box, Typography, Container } from "@mui/material";
import { motion } from "framer-motion";

const Aboutus = () => {
  return (
    <Box
      sx={{
        py: 8,
        backgroundColor: "#f4f4f4",
        textAlign: "center",
      }}
    >
      <Container maxWidth="md">
        <Typography
          variant="h4"
          component={motion.h2}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          sx={{ mb: 4, fontWeight: "bold", color: "#333" }}
        >
          About Us
        </Typography>

        <Typography
          variant="body1"
          component={motion.p}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          sx={{ mb: 4, color: "#555" }}
        >
          Paradise Provisions is a trusted provisioning partner for yacht
          owners, charter chefs, and captains throughout the Bahamas. With a
          commitment to sourcing the freshest local ingredients, we provide
          tailored provisioning solutions that cater to your specific needs.
          Whether it’s fresh seafood, farm-to-table produce, or specialty items,
          we ensure that our products meet the highest standards of quality.
        </Typography>

        <Typography
          variant="body1"
          component={motion.p}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          sx={{ mb: 4, color: "#555" }}
        >
          Led by Dion, who has over 9 years of experience in provisioning for
          yacht charters, Paradise Provisions has earned the trust of top chefs
          like Chef Manny Slomovits and Chef Johnny Kubic. Our services go
          beyond just delivering food—we source from local farmers and
          fishermen, providing the freshest and highest quality ingredients that
          the Bahamas has to offer.
        </Typography>

        <Typography
          variant="body1"
          component={motion.p}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.6 }}
          sx={{ color: "#555" }}
        >
          At Paradise Provisions, we believe in creating lasting relationships
          with our clients by offering exceptional service, reliability, and
          quality. If you’re planning your next charter season in the Bahamas,
          you can trust us to meet all of your provisioning needs on time and
          with professionalism.
        </Typography>
      </Container>
    </Box>
  );
};

export default Aboutus;
