import React from "react";
import { Box, Typography, Grid, Paper, Rating } from "@mui/material";
import { motion } from "framer-motion";

const testimonialsData = [
  {
    name: "Chef Manny Slomovits",
    feedback:
      "Dion has come through for me time and time again. He is professional, organized, on-time, and friendly. If he can’t find it or fly it in, you don’t need it!",
    rating: 5,
  },
  {
    name: "Chef Johnny Kubic",
    feedback:
      "Dion’s reliability is second to none. He has surpassed my expectations every time. When you don’t have time to waste, Dion is your man.",
    rating: 5,
  },
];

const Testimonials = () => {
  return (
    <Box
      sx={{
        py: 8,
        backgroundColor: "#f7f7f7",
        textAlign: "center",
      }}
    >
      <Typography
        variant="h4"
        component="h2"
        sx={{ mb: 4, fontWeight: "bold", color: "#333" }}
      >
        What Our Clients Say
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        {testimonialsData.map((testimonial, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Paper
              component={motion.div}
              whileHover={{ scale: 1.05 }}
              sx={{
                p: 4,
                textAlign: "center",
                backgroundColor: "#fff",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
                "{testimonial.feedback}"
              </Typography>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                - {testimonial.name}
              </Typography>
              <Rating
                name={`rating-${index}`}
                value={testimonial.rating}
                readOnly
                sx={{ mt: 2 }}
              />
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Testimonials;
