import React from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";
import { motion } from "framer-motion";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";

const services = [
  {
    title: "Yacht Provisioning",
    description:
      "We provide all your yacht provisioning needs with fresh, high-quality ingredients sourced from local farmers and fishermen in the Bahamas.",
    icon: (
      <LocalGroceryStoreIcon sx={{ fontSize: 50, color: "#3387b6", mb: 2 }} />
    ),
  },
  {
    title: "Local Farmers & Fishermen Sourcing",
    description:
      "We work closely with local farmers and fishermen to ensure fresh produce, seafood, and other ingredients that meet your requirements.",
    icon: <AgricultureIcon sx={{ fontSize: 50, color: "#3387b6", mb: 2 }} />,
  },
  {
    title: "Charter Season Services",
    description:
      "If you’re based in Nassau or planning a charter season in the Bahamas, we offer reliable provisioning services with a focus on quality and timeliness.",
    icon: <DirectionsBoatIcon sx={{ fontSize: 50, color: "#3387b6", mb: 2 }} />,
  },
];

const Services = () => {
  return (
    <Box
      sx={{
        py: 8,
        backgroundColor: "#f7f7f7",
        textAlign: "center",
      }}
    >
      <Typography
        variant="h4"
        component="h2"
        sx={{ mb: 4, fontWeight: "bold", color: "#333" }}
      >
        Our Services
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper
              component={motion.div}
              whileHover={{ scale: 1.05 }}
              sx={{
                p: 3,
                textAlign: "center",
                backgroundColor: "#fff",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              }}
            >
              {service.icon}
              <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                {service.title}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {service.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Services;
