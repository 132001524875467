import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  Container,
} from "@mui/material";
import { motion } from "framer-motion";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here (e.g., send to API or email service)
    console.log("Form submitted:", formData);
  };

  return (
    <Box
      sx={{
        py: 8,
        backgroundColor: "#f4f4f4",
        textAlign: "center",
      }}
    >
      <Container maxWidth="md">
        <Typography
          variant="h4"
          component={motion.h2}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          sx={{ mb: 4, fontWeight: "bold", color: "#333" }}
        >
          Contact Us
        </Typography>

        <Typography variant="body1" sx={{ mb: 4, color: "#555" }}>
          Reach out to us for any inquiries or provisioning requests. We look
          forward to hearing from you!
        </Typography>

        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={6}>
            {/* Contact Information */}
            <Box sx={{ textAlign: "left" }}>
              <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                Contact Details:
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Email:</strong> dion@paradiseprovisions.com
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Phone:</strong> +1 (242) 555-1234
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Location:</strong> Nassau, Bahamas
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            {/* Contact Form */}
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                variant="outlined"
                sx={{ mb: 3 }}
                value={formData.name}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label="Email"
                name="email"
                variant="outlined"
                sx={{ mb: 3 }}
                value={formData.email}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label="Message"
                name="message"
                variant="outlined"
                multiline
                rows={4}
                sx={{ mb: 3 }}
                value={formData.message}
                onChange={handleChange}
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{ width: "100%" }}
              >
                Submit
              </Button>
            </form>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Contact;
