import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { motion } from "framer-motion";

const Header = () => {
  return (
    <AppBar position="static" sx={{ backgroundColor: "#3387b6" }}>
      <Toolbar>
        {/* Logo / Brand Name */}
        <Typography
          variant="h6"
          component={motion.div}
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.6 }}
          sx={{ flexGrow: 1, fontWeight: "bold", color: "#fff" }}
        >
          Paradise Provisions
        </Typography>

        {/* Navigation Links */}
        <Box sx={{ display: { xs: "none", md: "flex" }, gap: 2 }}>
          <Button color="inherit">Home</Button>
          <Button color="inherit">About Us</Button>
          <Button color="inherit">Services</Button>
          <Button color="inherit">Testimonials</Button>
          <Button color="inherit">Contact Us</Button>
        </Box>

        {/* Mobile Menu Icon */}
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          sx={{ display: { xs: "flex", md: "none" } }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
