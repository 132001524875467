import React from "react";
import { Button, Container, Typography, Box } from "@mui/material";
import { motion } from "framer-motion";

const Home = () => {
  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      sx={{
        backgroundColor: "#f4f4f4",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        textAlign: "center",
      }}
    >
      <Container maxWidth="md">
        {/* Main Headline */}
        <Typography
          variant="h2"
          component={motion.h1}
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          sx={{
            fontWeight: "bold",
            mb: 2,
            textShadow: "2px 2px 5px rgba(0,0,0,0.8)",
            // fontSize: "3rem",
            // whiteSpace: "nowrap",
          }}
        >
          Food is Life. Create and Savor Yours.
        </Typography>

        {/* Subheading */}
        <Typography
          variant="h5"
          component={motion.h2}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          sx={{
            mb: 4,
            fontWeight: "300",
            textShadow: "1px 1px 3px rgba(0,0,0,0.6)",
          }}
        >
          Providing fresh, high-quality provisions from local farmers and
          fishermen across the Bahamas.
        </Typography>

        {/* Call to Action Buttons */}
        <Box
          component={motion.div}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5, delay: 0.7 }}
          sx={{ display: "flex", justifyContent: "center", gap: 2 }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ px: 4, backgroundColor: "#3387b6 " }}
          >
            Get Started
          </Button>
          <Button
            variant="outlined"
            size="large"
            sx={{
              px: 4,
              borderColor: "#3387b6",
              color: "#3387b6",
              "&:hover": {
                borderColor: "#3387b6",
                backgroundColor: "rgba(255,255,255,0.1)",
              },
            }}
          >
            Learn More
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Home;
